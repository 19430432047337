export default {
    mobile: {
        home: 'Ara. awal',
        usr_agreement: 'Perjanjian pengguna',
        terms_conditions: 'Syarat & Ketentuan',
        privacy_agreement: 'Kebijakan Privasi',
        account_request: 'Permintaan Penghapusan Akun',
        title: '《 Berani: pedang dan Sihir 》',
        name: 'Deskripsi permainan',
        info: '《 Berani: pedang dan Sihir 》Ini adalah permainan tangan MMORPG horizontal dengan gaya yuan sekunder. Model karakter gim ini sangat indah, dan gaya lukisan sekunder menghadirkan pengalaman baru. Pemain dalam game bisa bebas mencubit wajah, tidak ada pembagian pekerjaan yang jelas, senjata super berbeda bisa bebas dipakai, skill berubah sesuai dengan senjata, setiap kali Anda memasukkan salinan hanya bisa memilih senjata, di kota bisa bebas diaktifkan, settingnya baru, gameplaynya menarik, pemain tidak akan terkungkung dalam satu pekerjaan saja yang membosankan. Ada juga gameplay kompetitif PVT yang intens, pertempuran berdarah panas benar-benar mengasyikkan, rasakan tingkat kesulitan yang tinggi dari rantai gerakan yang tajam.',
        about: 'Hubungi Kami',
        img01: '/img/home13.svg',
        img02: '/img/home15.svg',


    },
}