export default {
    mobile: {
        home: 'Home',
        usr_agreement: 'User agreement',
        terms_conditions: 'Terms & Conditions',
        privacy_agreement: 'Privacy Policy',
        account_request: 'Account deletion request',
        title: '《 Brave: sword and Magic 》',
        name: 'Game Description',
        info: '《 Brave: sword and Magic 》is a horizontal MMORPG hand game in secondary yuan style. The game character model is exquisite, and the secondary yuan painting style brings a novel experience. Players in the game can be free to pinch the face, there is no clear division of occupations, a lot of different weapons can be freely worn, skills change according to the weapon, each time you enter the copy can only choose a weapon, in the town can be freely switched, the setting is novel, the gameplay is interesting, the player will not be confined to a single occupation of the uninteresting. There is also intense PVP competitive gameplay, hot-blooded battle is absolutely exciting, feel the high degree of difficulty of the chain of moves crisp feeling.',
        about: 'Contact us',
        img01: '/img/home01.svg',
        img02: '/img/home14.svg',























    },

}